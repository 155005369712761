import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { getAddress, signMessage } from 'sats-connect';
import logo from './images/verifyord.png';
import './App.css';

function App() {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const userId = params.get('userId');

  // Add new state variables
  const [walletSubmitted, setWalletSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const connectXverseWallet = useCallback(async () => {
    const getAddressOptions = {
      payload: {
        purposes: ['ordinals', 'payment'],
        message: 'Address for receiving Ordinals and payments',
        network: {
          type:'Mainnet'
        },
      },
      onFinish: async (walletResponse) => {
        const ordinalsWallet = walletResponse.addresses.find(wallet => wallet.purpose === 'ordinals');
      
        // Request the user to sign a message
        const signMessageOptions = {
          payload: {
            network: {
              type: "Mainnet",
            },
            address: ordinalsWallet.address, // Use the payment address to sign
            message: "Please sign this message to confirm ownership of this wallet. No transactions are broadcasted from this action.",
          },
          onFinish: async (signResponse) => {
            // Send a request to the server to update the addresses.json file
            const response = await fetch('https://verifyord.com:3001/update-addresses', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                userId: userId,
                ordinalsAddress: ordinalsWallet.address, // Use the ordinals address
                publicKey: ordinalsWallet.publicKey, // Use the public key of the payment address
                signedMessage: signResponse, // Include the signed message directly
                walletType: 'xverse',
              }),
            });
  
            if (response.ok) {
              // Update the state variable after the wallet is submitted
              setWalletSubmitted(true);
            } else {
              // Update the error message state variable
              const error = await response.text();
              setErrorMessage(error);
            }
          },
          onCancel: () => alert("Canceled"),
        };
        await signMessage(signMessageOptions);
      },
      onCancel: () => alert('Request canceled. Wallet not submitted. Please try again!'),
    }
  
    await getAddress(getAddressOptions);
  }, [userId]);

  const connectUnisatWallet = useCallback(async () => {
    try {
      let accounts = await window.unisat.requestAccounts();
      console.log('connect success', accounts);
  
      // Get the public key
      let publicKey;
      try {
        publicKey = await window.unisat.getPublicKey();
        console.log('public key success');
      } catch (e) {
        console.log('public key failed', e);
        alert('Failed to get public key. Please try again!');
        return;
      }
  
      // Ask the user to sign a message
      let signedMessage;
      try {
        signedMessage = await window.unisat.signMessage("Please sign this message to confirm ownership of this wallet. No transactions are broadcasted from this action.");
        console.log('signature success');
      } catch (e) {
        console.log('signature failed', e);
        alert('Failed to sign message. Please try again!');
        return;
      }
  
      // Send a request to the server to update the addresses.json file
      const response = await fetch('https://verifyord.com:3001/update-addresses', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId,
          ordinalsAddress: accounts[0], // Send unisatAddress as ordinalsAddress
          signedMessage: signedMessage,
          publicKey: publicKey, // Include the public key
          walletType: 'unisat',
        }),
      });
  
      if (response.ok) {
        // Update the state variable after the wallet is submitted
        setWalletSubmitted(true);
      } else {
        // Update the error message state variable
        const error = await response.text();
        setErrorMessage(error);
      }
    } catch (e) {
      console.log('connect failed');
      alert('Request canceled. Wallet not submitted. Please try again!');
    }
  }, [userId]);

  useEffect(() => {
    connectXverseWallet();
  }, [connectXverseWallet]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Logo" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '40%' }} />
        {userId ? (
          walletSubmitted ? (
            <p>Wallet submitted successfully. Please return to Discord.</p>
          ) : (
            <>
              {errorMessage && <p>{errorMessage}</p>}
              <button className="Connect-button" onClick={connectXverseWallet}>
                Connect Xverse Wallet
              </button>
              <button className="Connect-button" onClick={connectUnisatWallet}>
                Connect Unisat Wallet
              </button>
            </>
          )
        ) : (
          <p>Use the /verify command in your Discord server to verify a wallet.</p>
        )}
      </header>
    </div>
  );
}

export default App;